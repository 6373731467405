<template>
  <div>
    <a-page-header
      title="返回"
      :sub-title="this.$route.query.id ? '修改视频信息' : '添加视频'"
      @back="() => $router.go(-1)"
    />

    <a-row>
      <a-col :span="10">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-form-item label="标题">
            <a-input v-model="addFromData.title" />
          </a-form-item>

          <a-form-item label="选择栏目">
            <GroupSelect
              @GroupSelect="GroupSelect"
              :selectId="addFromData.group_id"
              style="width:200px"
            ></GroupSelect>
          </a-form-item>

          <a-form-item label="选择企业">
            <CompanySelect
              style="width:200px"
              @CompanySelect="CompanySelect"
              :serchKeyword="addFromData.company_name"
              :selectId="addFromData.company_id"
            ></CompanySelect>
          </a-form-item>

          <a-form-item label="封面">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadImage"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                style="display:block;max-width:100px;max-height:100px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-item>

          <a-form-item label="视频上传">
            <a-upload
              accept=".rm,.mp4,.m4v,.rmvb,.dvix,.3gp,.mov,.dat ,.mkv,.flv"
              action="https://up-cn-east-2.qiniup.com"
              :before-upload="beforeUploadVideo"
              :data="videoForm"
              :headers="headers"
              @change="handleVideoChange"
              :fileList="fileList"
            >
              <a-button
                type="primary"
                :disabled="fileList.length > 0"
                :loading="videoLoading"
              >
                <a-icon type="upload" /> 视频上传
              </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="10">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-item label="点击量">
            <a-input-number
              v-model="addFromData.post_hits"
              style="width:160px"
            />
          </a-form-item>
          <a-form-item label="点赞量">
            <a-input-number
              v-model="addFromData.post_like"
              style="width:160px"
            />
          </a-form-item>
          <a-form-item label="收藏量">
            <a-input-number
              v-model="addFromData.post_favorites"
              style="width:160px"
            />
          </a-form-item>
          <a-form-item label="排序">
            <a-input-number
              :min="0"
              v-model="addFromData.list_order"
              style="width:160px"
            />
          </a-form-item>
   <a-form-item label="创建时间">
            <a-date-picker
              tyle="width:160px"
              format="YYYY-MM-DD HH:mm:ss"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              show-time
              :locale="locale"
              v-model="addFromData.create_time"
            />
          </a-form-item>

          <a-form-item label="是否置顶">
            <a-radio-group v-model="addFromData.is_top">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="是否推荐">
            <a-radio-group v-model="addFromData.recommended">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>  
       

          <a-form-item>
            <a-button
              type="primary"
              style="margin-left:100px"
              @click="handleOk"
            >
              保存
            </a-button></a-form-item
          >
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  uploadImage,
  getQiniuToken,
  directOneSaveFile,
  getVideoTime,
  videoDataSave,
  videoDetail,
} from "@/apis/index";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
export default {
  data() {
    return {
      locale,
      uploadImage,
      addFromData: {
        id: "",
        title: "",
        video_id: "",
        group_id: undefined,
        post_hits: "",
        post_favorites: "",
        post_like: "",
        list_order: "",
        thumbnail: "",
        is_top: 0,
        recommended: 0,
        company_id: undefined,
        video_time: "",
        company_name: undefined,
        create_time:''
      },
      fileList: [],
      videoLoading: false,
      imageUrl: "",
      loading: false,
      headers: {
        token: "",
      },
      videoForm: {
        token: "",
        key: "",
      },
      params: {},
    };
  },
  computed: {},
  created() {
    getQiniuToken().then((res) => {
      if (res.code == 1) {
        this.headers.token = res.data.uptoken;
        this.videoForm.token = res.data.uptoken;
      }
    });
    if (this.$route.query.id) {
      videoDetail({ id: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          this.addFromData = { ...res.data };
          if (res.data.company) {
            this.addFromData.company_name = res.data.company.company_name;
          } else {
            this.addFromData.company_id = undefined;
            this.addFromData.company_name = undefined;
          }

          if (res.data.create_time > 0) {
            this.addFromData.create_time = moment(
              Number(res.data.create_time + "000")
            ).format("YYYY-MM-DD HH:mm:ss");
          } else {
            this.addFromData.create_time = "";
          }


          this.imageUrl = res.data.thumbnail_url;
        }
      });
    }
  },
  methods: {
    CompanySelect(val) {
      this.addFromData.company_id = val;
    },
    GroupSelect(val) {
      this.addFromData.group_id = val;
    },
    handleOk() {
      videoDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$message.success("视频保存成功");
          this.$router.push({ path: "/contents/video" });
        }
      });
    },
    handleVideoChange(info) {
      this.fileList = info.fileList;
      if (info.file.status === "uploading") {
        this.videoLoading = true;
      }
      if (info.file.status === "done") {
        this.params = {
          file_hash: info.file.response.hash,
          file_key: info.file.response.key,
          origin_name: info.file.name,
        };
        this.videoLoading = false;
        directOneSaveFile(this.params).then((res) => {
          if (res.code == 1) {
            this.addFromData.video_id = res.data.id;
            getVideoTime({ id: res.data.id }).then((res) => {
              if (res.code == 1) {
                this.addFromData.video_time = res.data.video_time;
              }
            });
          }
        });
      }
      if (info.file.status === "removed") {
        this.addFromData.video_id = "";
      }
    },
    beforeUploadVideo(file) {
      this.addFromData.video_time = "";
      var file_ext = file.name.substr(file.name.lastIndexOf("."));
      var file_dir = "files/" + moment(new Date()).format("YYYYMMDD") + "/";
      var file_name =
        parseInt(new Date().valueOf() / 1000) +
        "-" +
        parseInt(Math.random() * 8999 + 1000) +
        file_ext;
      this.videoForm.key = file_dir + file_name;
      const isLt2M = file.size / 1024 / 1024 < 500;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 500MB!");
        return false;
      }
    },
    // 封面图
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.imageUrl = info.file.response.data.url;
        this.addFromData.thumbnail = info.file.response.data.file_path;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped></style>
